import { createI18n } from "vue-i18n";

const messages = {
  en: {
    welcome_message: "Hello",
    registerfrom: [
      {
        label : "Register",
        fullname: "Name & Surname",
        nickname: "Nickname",
        gender: "Gender",
        birthdate: "Date of Birth",
        birthdate_d: "Date",
        birthdate_m: "Month",
        birthdate_y: "year",
        nationality: "Nationality",
        country: "Country",
        tel: "Phone NO.",
        email: "Email",
        passport: "Passport No.",
      },
    ],
  },
  th: {
    welcome_message: "สวัสดีคุณ",
    registerfrom: [
      {
        label : "สมัครสมาชิก",
        fullname: "ชื่อ-นามสกุล",
        nickname: "ชื่อเล่น",
        gender: "เพศ",
        birthdate: "วันเกิด",
        birthdate_d: "วัน",
        birthdate_m: "เดือน",
        birthdate_y: "ปี",
        "่job": "อาชีพ",
        tel: "เบอร์โทร",
        email: "อีเมลล์",
        citizen_id: "เลขบัตรประชาชน",
        citizen_exp_date: "วันหมดอายุบัตร",
        upload_citizen_pic: "อัพโหลดภาพถ่ายหน้าบัตรประชาชน",
        citizen_address: "ที่อยู่ตามบัตรประชาชน",
        province: "จังหวัด",
        amphoe: "อำเภอ/เขต",
        tumbol: "ตำบล/แขวง",
        post_id: "รหัสไปรษณีย์",
      },
    ],
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "th",
  globalInjection: true,
  messages,
});

export default i18n;
