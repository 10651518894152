<template>
  <div class="row">
    <!-- <div class="app">
      <p>{{ translate("welcome_message") }}</p>

      <br />
      <button @click="setLang('th')">th</button>
      <button @click="setLang('en')">en</button>
    </div> -->
    <div class="col-12">
      <div class="card concard card-shadow">
        <div class="card-header text-center">
          <img src="@/assets/logo.png" style="width:100px; background-color: none; margin-top: 1rem;"  alt="..." />
        </div>
        <div class="card-body mb-47">
          <p class="t1 mb-3 text-center">
            <strong
              >ข้อกำหนดและเงื่อนไขการใช้บริการ Line Application “{{
                name_line
              }}” สำหรับการใช้งานแอปพลิเคชัน ในฐานะบุคคลทั่วไป</strong
            >
          </p>
          <p class="t2" style="text-indent: 40px">
            ข้าพเจ้า ซึ่งเป็นบุคคลทั่วไป ตกลงใช้บริการ
            <strong>Line Application</strong>
            “{{ name_line }}” โดย รับทราบ ยอมรับ
            และตกลงที่จะผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการ
            ดังต่อไปนี้
          </p>
          <ol class="t2 pl-5 sub-items mb-10" style="line-height: 180%">
            <li class="level-1">
              <strong><u>นิยามศัพท์</u></strong>
              <ol class="sub-items indent">
                <li>
                  <strong>“แอปพลิเคชัน”</strong>
                  หรือ
                  <strong>“Application”</strong>
                  หมายความว่า
                  <strong>Line Application “{{ name_line }}”</strong>
                  ตลอดจนโปรแกรมคอมพิวเตอร์ ชุดคำสั่ง หรือแอปพลิเคชันอื่น ๆ
                  ที่ต้องใช้หรืออาจใช้ร่วมกันหรือเกี่ยวเนื่องกันกับ Line
                  application ดังกล่าว เพื่อการใช้บริการ
                  <strong>“{{ name_line }}”</strong>
                  ไม่ว่าจะใช้งานผ่านโทรศัพท์มือถือ แท็บเล็ต เว็บ
                  หรือผ่านอุปกรณ์อื่นใดก็ตาม ทั้งนี้ ในกรณีที่ Line application
                  ดังกล่าวเปลี่ยนชื่อเป็นอย่างอื่นหรือมีแอปพลิเคชันอื่นรวมอยู่ด้วย
                  ให้หมายความรวมถึงแอปพลิเคชันนั้นด้วย
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-center align-center">
            <button @click="next" class="button">ยอมรับ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "Register",
  setup() {
    //

    const { t, te } = useI18n();
    const i18n = useI18n();

    i18n.locale.value = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    const store = inject("store");
    const router = inject("router");
    const name_line = ref(process.env.VUE_APP_NAME_LINE);

    onMounted(() => {
      // console.log(i18n.locale);
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const next = () => {
      let form = {
        id_line: store.getters.getAccessToken.accesstoken,
        agreement: true,
      };
      store.dispatch(SET_REGISTER, form);
      router.push({ name: "Register_1" });
    };

    // const displayLocale = computed(() => {
    //   if (18n.locale.value === "th") {
    //     return "th";
    //   }
    //   return "en";
    // });

    // const setLang = () => {
    //   if (18n.locale.value == "th") {
    //     i18n.locale.value = "en";
    //   } else {
    //     i18n.locale.value = "th";
    //   }
    //   // localStorage.setItem("lang", lang);
    // };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    // const switchLocale = () => {
    //   //onsole.log('action');
    //   if (i18n.locale === "th") {
    //     console.log("action th");
    //     i18n.locale = "en";
    //   } else {
    //     console.log("action en");
    //     i18n.locale = "th";
    //   }
    // };

    return {
      name_line,
      next,
      translate,
      setLang,
    };
  },
});
</script>

<style scoped>
.card-header {
  padding: 0;
  margin-bottom: 0;
  border-bottom: none;
  background-color: transparent;
}
.card-body {
  margin-top: 0;
  background-color: transparent;
  flex: unset !important;
  padding: 16px;
}
.card-footer {
  margin-top: 0;
  background-color: transparent !important;
  border: none;
}
</style>
