import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ApiService from "@/service/ApiService";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// import { createI18n } from "vue-i18n";

// const i18n = createI18n({
//   locales: ["en", "th"],
//   defaultLocale: "th",
//   vueI18n: {
//     fallbackLocale: "en",
//     messages: {
//       en: require("./static/lang/en.json"),
//       th: require("./static/lang/th.json"),
//     },
//   },
// });

import i18n from "@/core/plugins/i18n";

const app = createApp(App);

ApiService.init(app);

app.use(i18n);
app.use(store);
app.use(router);
//app.provide("i18n", i18n);
app.provide("store", store);
app.provide("router", router);
app.mount("#app");
